import {observer} from "mobx-react-lite";
import {useContext, useEffect, useMemo} from "react";
import {AdminStore} from "./stores/admin_store";
import {AdminStoreContext} from "./protected_route";
import styled, {useTheme} from "styled-components";
import {AdminButton, AdminScaffold} from "./admin_scaffold";
import {CvvRoutes} from "../strings";
import {
    AdminHeader,
    AdminInputContainer,
    AdminInputLabel,
    AdminInput, AdminFooter
} from "./components/admin_decorations";
import {Theme} from "../theme";
import {useNavigate, useParams} from "react-router-dom";
import {AdminRazpisaniTecajStore} from "./stores/admin_razpisani_tecaj_store";
import {MdArrowBack} from "react-icons/md";
import {MdCheck} from "react-icons/md";
import {IconButton} from "../forms/components/decorations";
import {admin_tecaj} from "../proto/compiled";
import {DateFormatters, DateUtils} from "../utils";
import {LoadingOverlay} from "../components/overlays";
import DatePicker from 'react-date-picker'
import {AdminDatepickerStyles, DatepickerStyles} from "../components/datepicker_styles";

const Container = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;

const Select = styled.select`
  margin-right: 8px;
`;

export const AdminRazpisaniTecaj = observer(() => {
    const {id} = useParams();

    const adminStore = useContext(AdminStoreContext) as AdminStore;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const store = useMemo(() => new AdminRazpisaniTecajStore(adminStore, id), [id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => store.dispose(), [id]);

    const navigate = useNavigate();
    const theme = useTheme() as Theme;
    return <AdminScaffold route={CvvRoutes.admin.razpisaniTecaji}>
        <LoadingOverlay builder={(action) => {
            return <Container>
                <AdminHeader>
                    <IconButton onClick={() => navigate(-1)}
                                style={{color: 'white', height: '100%', aspectRatio: '1'}}>
                        <MdArrowBack/>
                    </IconButton>
                    {id ? 'UREDI' : 'NOV'} RAZPISANI TECAJ
                    <div style={{marginLeft: 'auto'}}>{id ?
                        <span>{store.razpisaniTecaj?.tecajTip?.name ?? '...'} &gt; {store.razpisaniTecaj?.tecaj?.name ?? '...'}</span> : <>
                            <Select value={store.tecajTipKey}
                                    onChange={(e) => store.setTecajTip(e.target.value)}>
                                <option value={''}>---- izberi tip ----</option>
                                {adminStore.meta?.tecajTipi?.map((t) => <option key={t.text!}
                                                                                value={t.text!}>{t.name}</option>)}
                            </Select>
                            <Select value={store.tecajKey} onChange={(e) => store.setTecaj(e.target.value)}>
                                <option value={''}>---- izberi tecaj ----</option>
                                {adminStore.meta &&
                                    adminStore.meta?.tecajTipTecaji![store.tecajTipKey!]?.choices?.map(
                                        (t) => <option key={t.text!} value={t.text!}>{t.name}</option>
                                    )}
                            </Select>
                        </>}</div>
                </AdminHeader>
                {!store.razpisaniTecaj && <span style={{marginTop: '8px'}}>Izberite tip tečaja.</span>}
                {!!store.razpisaniTecaj && <div style={{marginTop: '8px', width: '100%'}}>
                    <AdminHeader>
                        PODATKI
                    </AdminHeader>
                    <AdminInputContainer>
                        <AdminInputLabel>Objavi</AdminInputLabel>
                        <AdminInput>
                            <input type={"checkbox"}
                                   checked={store.razpisaniTecaj!.published!}
                                   onChange={() => store.razpisaniTecaj!.published = !store.razpisaniTecaj!.published!}/>
                        </AdminInput>
                    </AdminInputContainer>
                    <AdminInputContainer>
                        <AdminInputLabel>Št. udeležencev</AdminInputLabel>
                        <AdminInput>
                            <input type={"number"}
                                   min={0}
                                   value={store.razpisaniTecaj!.placesTotal!}
                                   onChange={(e) => store.razpisaniTecaj!.placesTotal = e.target.valueAsNumber}/>
                        </AdminInput>
                    </AdminInputContainer>
                    {/*<AdminInputContainer>*/}
                    {/*    <AdminInputLabel>Otroci</AdminInputLabel>*/}
                    {/*    <AdminInput>*/}
                    {/*        <input type={"checkbox"}*/}
                    {/*               checked={store.razpisaniTecaj!.otroci!}*/}
                    {/*               onChange={() => store.razpisaniTecaj!.otroci = !store.razpisaniTecaj!.otroci!}/>*/}
                    {/*    </AdminInput>*/}
                    {/*</AdminInputContainer>*/}
                    <AdminInputContainer>
                        <AdminInputLabel>Opomba</AdminInputLabel>
                        <AdminInput>
                            <input type={"text"}
                                   min={0}
                                   style={{width: '100%'}}
                                   value={store.razpisaniTecaj!.comment!}
                                   onChange={(e) => store.razpisaniTecaj!.comment = e.target.value}/>
                        </AdminInput>
                    </AdminInputContainer>
                    <AdminInputContainer>
                        <AdminInputLabel>Opomba (interna)</AdminInputLabel>
                        <AdminInput>
                            <input type={"text"}
                                   min={0}
                                   style={{width: '100%'}}
                                   value={store.razpisaniTecaj!.commentInternal!}
                                   onChange={(e) => store.razpisaniTecaj!.commentInternal = e.target.value}/>
                        </AdminInput>
                    </AdminInputContainer>
                    <AdminInputContainer>
                        <AdminInputLabel>Inštruktor</AdminInputLabel>
                        <AdminInput>
                            <Select value={store.razpisaniTecaj!.instructor?.num ?? ''}
                                    style={{width: '200px', marginRight: '0'}}
                                    onChange={(e) => store.razpisaniTecaj!.instructor!.num = parseInt(e.target.value) || undefined}>
                                <option value={''}>---- izberi inštruktorja ----</option>
                                {store.tecajInstructors?.instructors?.map((t) => <option key={t.num!}
                                                                                         value={t.num!}>{t.name?.toUpperCase()}</option>)}
                            </Select>
                        </AdminInput>
                    </AdminInputContainer>
                    <AdminInputContainer>
                        <AdminInputLabel>Načini plačila</AdminInputLabel>
                        <AdminInput>
                            <input type={"checkbox"}
                                   checked={store.razpisaniTecaj!.paymentKreditna!}
                                   onChange={() => store.razpisaniTecaj!.paymentKreditna = !store.razpisaniTecaj!.paymentKreditna!}/>
                            <span style={{marginRight: '24px', marginLeft: '4px'}}>kreditna</span>
                            <input type={"checkbox"}
                                   checked={store.razpisaniTecaj!.paymentGotovina!}
                                   onChange={() => store.razpisaniTecaj!.paymentGotovina = !store.razpisaniTecaj!.paymentGotovina!}/>
                            <span style={{marginRight: '24px', marginLeft: '4px'}}>gotovina</span>
                            <input type={"checkbox"}
                                   checked={store.razpisaniTecaj!.paymentDarilniBon!}
                                   onChange={() => store.razpisaniTecaj!.paymentDarilniBon = !store.razpisaniTecaj!.paymentDarilniBon!}/>
                            <span style={{marginRight: '24px', marginLeft: '4px'}}>darilna kartica</span>

                        </AdminInput>
                    </AdminInputContainer>
                </div>}
                {store.razpisaniTecaj?.days?.map((d, i) => <Day key={i} day={d} n={i + 1}/>)}
                <AdminFooter>
                    {store.error && <theme.textTheme.error.span>{store.error}</theme.textTheme.error.span>}
                    {store.saved &&
                        <theme.textTheme.body.span
                            style={{color: 'green', fontWeight: 'bold', display: 'inline-flex', alignItems: 'center'}}>
                            <MdCheck style={{fontSize: '20px', marginRight: '8px'}}/> Shranjeno.
                        </theme.textTheme.body.span>}
                    <AdminButton
                        onClick={() => action(store.save())}
                        style={{marginLeft: 'auto'}}>
                        SHRANI
                    </AdminButton>
                </AdminFooter>
            </Container>;
        }
        }/>
    </AdminScaffold>
});


const Day = observer(({n, day}: { n: number, day: admin_tecaj.IAdminRazpisaniTecajDay }) => {
    const adminStore = useContext(AdminStoreContext) as AdminStore;
    if (!day.location?.text) {
        if (adminStore.meta?.locations?.length) {
            day.location!.text = adminStore.meta.locations[0].text!;
        } else {
            day.location!.text = '';
        }
    }

    let dateStart: Date | undefined;
    let dateEnd: Date | undefined;

    if (day.start?.ts) {
        dateStart = new Date(day.start.ts as number);
    }

    if (day.end?.ts) {
        dateEnd = new Date(day.end.ts as number);
    }

    const invalid = dateEnd && dateStart && dateEnd.getTime() <= dateStart.getTime();
    return <div style={{marginTop: '8px', width: '100%'}}>
        <AdminHeader>
            {n}. DAN
        </AdminHeader>
        <AdminInputContainer>
            <AdminInputLabel>Lokacija, dan, ura od / do</AdminInputLabel>
            <AdminInput>
                <Select value={day.location!.text!}
                        style={{width: '150px', marginRight: '0'}}
                        onChange={(e) => day.location!.text = e.target.value}>
                    {adminStore.meta?.locations?.map((t) => <option key={t.text!}
                                                                    value={t.text!}>{t.name?.toUpperCase()}</option>)}
                </Select>
                <span>datum</span>
                <AdminDatepickerStyles>
                    <DatePicker
                        value={dateStart}
                        format={'dd. MM. y'}
                        onChange={(date?: Date) => {
                            if (!date) {
                                day.start!.ts = undefined;
                                day.end!.ts = undefined;
                                return;
                            }
                            dateStart = dateStart ?? new Date();
                            dateEnd = dateEnd ?? new Date(Date.now() + 3600e3);
                            day.start!.ts = DateUtils.setDate(dateStart, date).getTime();
                            day.end!.ts = DateUtils.setDate(dateEnd, dateStart).getTime();
                        }}/>
                </AdminDatepickerStyles>
                <span>od</span>
                <input type={"time"}
                       style={{
                           backgroundColor: invalid ? '#FFCCCC' : undefined,
                           borderRadius: '3px',
                           border: 'solid 1px gray'
                       }}
                       value={dateStart ? DateFormatters.isoTime(dateStart) : ''}
                       onChange={(e) => {
                           dateStart = dateStart ?? new Date();
                           dateEnd = dateEnd ?? new Date(Date.now() + 3600e3);
                           day.start!.ts = DateUtils.setTime(dateStart, e.target.value).getTime();
                       }}/>
                <span>do</span>
                <input type={"time"}
                       style={{
                           backgroundColor: invalid ? '#FFCCCC' : undefined,
                           borderRadius: '3px',
                           border: 'solid 1px gray'
                       }}
                       value={dateEnd ? DateFormatters.isoTime(dateEnd) : ''}
                       onChange={(e) => {
                           dateStart = dateStart ?? new Date();
                           dateEnd = dateEnd ?? new Date(Date.now() + 3600e3);
                           day.end!.ts = DateUtils.setTime(dateEnd, e.target.value).getTime()
                       }}/>
            </AdminInput>
        </AdminInputContainer>

    </div>;
});